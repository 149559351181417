.modal {
  .mark {
    z-index: 1000;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #0000003a;
  }

  .modal-wrap {
    position: fixed;
    width: 800px;
    height: 400px;
    top: calc(50% - 300px);
    left: calc(50% - 400px);
    background-color: #fff;
    z-index: 1000;
  }
  .header {
    height: 45px;
    width: 100%;
    padding: 0px 24px;
    line-height: 45px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    span {
      font-weight: 700;
    }
  }
  .content {
    width: 100%;
    display: flex;
    background-color: rgba(0, 0, 0, 0.04);
    justify-content: space-between;
    .left {
      width: calc(60% - 2px);
      height: 354px;
      overflow-y: none;
      background-color: #fff;
    }
    .right {
      width: calc(40% - 2px);
      height: 354px;
      background-color: #fff;

      overflow-y: auto;
    }
    .tool {
      background-color: rgba(0, 0, 0, 0.08);
      height: 30px;
      line-height: 30px;
      display: flex;
      justify-content: space-between;
      padding: 0px 24px;
      span {
        color: rgba(0, 0, 0, 0.6);
        font-weight: 700;
      }
    }
    .checkedIndex-box {
      display: flex;
      cursor: pointer;
      justify-content: space-between;
      .close {
        display: none;
      }
    }
    .checkedIndex-box:hover {
      color: rgb(24, 144, 255);
      .close {
        display: block;
      }
    }
    .checkBox-panel {
      padding: 0px 24px;
      height: 320px;
      overflow: auto;
      padding-bottom: 24px;
    }

    .groupTitle {
      height: 40px;
      font-size: 14px;
      line-height: 40px;
      font-family: pingfangSC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
    .checktitle {
      font-family: pingfangSC;
      font-weight: 700;
      padding-top: 20px;
    }
    .item-wrap {
      display: flex;
      flex-wrap: wrap;
      padding-left: 40px;
      .item {
        width: 50%;

        display: flex;
        .des1 {
          width: 100%;
        }
      }

      .lastItem {
        width: 95%;
      }
    }
  }
  .check {
    color: rgb(24, 144, 255);
    cursor: pointer;
  }
  .bottom-bottom {
    display: flex;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
    background-color: #fff;
    padding: 0px 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}

@primary-color: #333333;@layout-body-background: #f2f3f5;@menu-item-active-bg: #F2F3F5;@select-item-selected-bg: #F2F3F5;