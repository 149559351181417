// .wrap{
//     // :global {
//         .title{
//             height: 48px;
//             line-height: 48px;
//             font-size: 14px;
//             font-family: PingFangSC-Medium, "PingFang SC";
//             font-weight: 500;
//             color: rgb(38, 38, 38);
//             border-bottom: 1px solid rgb(240,240,240);
//         }
//     // }
// }

.wrap {
  :global {
    .title {
      height: 48px;
      line-height: 48px;
      font-size: 14px;
      font-family: PingFangSC-Medium, 'PingFang SC';
      font-weight: 500;
      color: rgb(38, 38, 38);
    }
    .tool2 {
      height: 48px;
      color: #262626;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;

      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }
    .toolBar {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      background-color: #fff;
      padding: 12px;
    }
    .toolLine {
      min-width: 260px;
      line-height: 30px;
    }
    .bottomTool {
      padding: 12px;
      background-color: #fff;
    }
    .time {
      margin-left: 24px;
    }
  }
}

@primary-color: #333333;@layout-body-background: #f2f3f5;@menu-item-active-bg: #F2F3F5;@select-item-selected-bg: #F2F3F5;