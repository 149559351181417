.container {
  display: flex;
  min-width: 850px;
  height: 100vh;
  .ant-layout-sider {
    & ::-webkit-scrollbar {
      background-color: #001529;
    }
    & ::-webkit-scrollbar-thumb {
      background-color: #41444b;
    }
  }
  .ant-layout {
    /* 防止 tabs 超出不收缩 */
    overflow-x: hidden;
    .ant-layout-content {
      box-sizing: border-box;
      padding: 20px;
      margin: 10px 13px;
      overflow: auto;
      background-color: #ffffff;
      border-radius: 4px;
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
      .content {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@primary-color: #333333;@layout-body-background: #f2f3f5;@menu-item-active-bg: #F2F3F5;@select-item-selected-bg: #F2F3F5;