.wrap {
  :global {
    .title {
      height: 48px;
      color: #262626;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 48px;
      display: flex;

      justify-content: space-between;
    }
    .title1 {
      height: 12px;
    }
    .time {
      right: 4px;
      bottom: 6px;
      position: absolute;
    }
    .searchItem {
      width: 260px;

      .spantitle {
        width: 100px;
        padding-left: 2px;
        display: block;
        text-align: left;
      }
    }
  }
}

/* day css*/
.mode {
  color: #000;
  background-color: #fff;
}

/*dark css*/
.mode {
  color: #fff;
  background-color: #000;
}

@primary-color: #333333;@layout-body-background: #f2f3f5;@menu-item-active-bg: #F2F3F5;@select-item-selected-bg: #F2F3F5;