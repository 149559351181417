.wrap {
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  padding: 0 20px;
  // box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  border: 1px solid #e5e6eb;
  :global {
    .header {
      height: 100%;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      .circle {
        width: 24px;
        height: 24px;
        background-color: #333333;
        border-radius: 50%;
      }
      .user {
        margin-left: 8px;
        cursor: pointer;
      }
      .title {
        margin-left: 8px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
      }
      .left,
      .right {
        display: flex;
        align-items: center;
        // justify-content: start;
      }
    }
  }
}

@primary-color: #333333;@layout-body-background: #f2f3f5;@menu-item-active-bg: #F2F3F5;@select-item-selected-bg: #F2F3F5;