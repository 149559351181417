.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 100vw;
  height: 100vh;

  :global {
    .title {
      font-size: 33px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 28px;
    }

    .input {
      // height: 40px;
      width: 368px;
    }
    .btn {
      width: 368px;
    }
  }
}

@primary-color: #333333;@layout-body-background: #f2f3f5;@menu-item-active-bg: #F2F3F5;@select-item-selected-bg: #F2F3F5;