.watermark {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /* width: 100%;
  height: 100%; */
  /* background-color: white; */
  /* opacity: 0.5; */
  z-index: 99999999;
  pointer-events: none;
}
