// .wrap{
//     // :global {
//         .title{
//             height: 48px;
//             line-height: 48px;
//             font-size: 14px;
//             font-family: PingFangSC-Medium, "PingFang SC";
//             font-weight: 500;
//             color: rgb(38, 38, 38);
//             border-bottom: 1px solid rgb(240,240,240);
//         }
//     // }
// }

.title {
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  font-family: PingFangSC-Medium, 'PingFang SC';
  font-weight: 500;
  color: rgb(38, 38, 38);
  border-bottom: 1px solid rgb(240, 240, 240);
}

@primary-color: #333333;@layout-body-background: #f2f3f5;@menu-item-active-bg: #F2F3F5;@select-item-selected-bg: #F2F3F5;