.wrap {
  :global {
    .radioTool {
      background-color: #fff;
      margin: 12px 0px;
      padding: 4px 12px;
    }
    .tool {
      height: 48px;
      color: #262626;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;

      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    .title {
      height: 48px;
      color: #262626;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;

      line-height: 48px;
      width: 100%;
    }
    .radioBox {
      position: absolute;
      right: 12px;
      top: -40px;
    }
    .line {
      display: flex;

      .line-1-left,
      .line-1-right {
        flex: 1;
        width: 50%;
      }
    }
    .toolLine {
      width: 260px;
      line-height: 30px;
    }
    .campaign-filter {
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .compaign-content {
      display: flex;
      .compaign-left,
      .compaign-right {
        flex: 1;
      }

      .compaign-right {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding-left: 8px;
      }
      .compaign-left {
        height: 630px;
        position: relative;
        padding-right: 8px;
        width: 50%;
      }
    }
    .downLoad {
      width: 100%;
      height: 30px;
      text-align: right;
    }
    .downLoadTable {
      width: 100px;
      height: 30px;
      position: absolute;
      padding-right: 24px;
      text-align: right;
      top: 16px;
      right: 0px;
    }
    .myCell {
      font-weight: 600;
      background-color: #fafafa;
    }
    .Crossbar3Filter {
      position: absolute;
      right: 4px;
      top: 16px;
    }
  }
}

@primary-color: #333333;@layout-body-background: #f2f3f5;@menu-item-active-bg: #F2F3F5;@select-item-selected-bg: #F2F3F5;