.wrap {
  background-color: #fff;
  padding: 24px;
  padding-top: 0;
  // padding-bottom: 0;
  :global {
    .header {
      width: 100%;
      min-height: 56px;
      align-items: center;
      // line-height: 56px;
      // padding: 0 24px;
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        min-width: 270px;
      }
    }
  }
}

@primary-color: #333333;@layout-body-background: #f2f3f5;@menu-item-active-bg: #F2F3F5;@select-item-selected-bg: #F2F3F5;