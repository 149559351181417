.wrap {
  :global {
    .title {
      height: 48px;
      color: #262626;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 48px;
      display: flex;
      justify-content: space-between;
    }
    .line {
      display: flex;
      .line-1 {
        width: 100%;
      }
      .line-1-left,
      .line-1-right,
      .line-1 {
        position: relative;
        flex: 1;
        width: 50%;
        .average {
          position: absolute;

          top: 50px;
          left: 15px;
          font-size: 10px;
          color: #333;
        }
        .average2 {
          position: absolute;

          top: 70px;
          left: 15px;
          font-size: 10px;
          color: #333;
        }
        .average1 {
          position: absolute;
          top: 15px;
          right: 15px;
          font-size: 10px;
          color: #333;
        }
        .title {
          height: 48px;
          color: #262626;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          line-height: 48px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
    .toolLine {
      width: 260px;
      line-height: 30px;
    }
    .campaign-filter {
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .compaign-content {
      display: flex;
      .compaign-left,
      .compaign-right {
        flex: 1;
      }
      .compaign-left {
        display: flex;
        flex-direction: column;
        .left-top,
        .left-bottom {
          // height: 350px;
        }
      }
      .compaign-right {
        height: 700px;
      }
    }
    .pie2 {
      width: 150px;
      height: 150px;
    }
    .momentCostByAxis {
      width: 150px;
      height: 100px;
      position: absolute;
      right: 0px;
      bottom: -20px;
    }
    @media (max-width: 1360px) {
      .pie2 {
        width: 150px;
        height: 150px;
      }
      .momentCostByAxis {
        width: 150px;
        height: 150px;
        position: absolute;
        right: -20px;
        bottom: -70px;
      }
    }

    .downBtn {
      position: absolute;

      right: -40px;
      top: 10px;
    }
    .assBtn {
      right: 4px;
      top: 12px;
      position: absolute;
    }
  }
}

@primary-color: #333333;@layout-body-background: #f2f3f5;@menu-item-active-bg: #F2F3F5;@select-item-selected-bg: #F2F3F5;