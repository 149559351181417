.wrap {
  // background-color: #fff;
  :global {
    .division-filter {
      padding: 0 24px;
      height: 64px;
      line-height: 64px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
    }
    .tool {
      border-bottom: dashed 1px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;
    }
    .header {
      width: 100%;
      min-height: 56px;
      align-items: center;
      // line-height: 56px;
      padding: 0 24px;
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        width: 100%;
        display: flex;
        justify-content: space-between;
        span {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    .closeTool {
      width: 120px;
      text-align: center;
    }
    .yearmonthbudget {
      padding: 0px 24px 12px 24px;
      position: relative;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
      // background-color: red;
      // margin-top: 16px;
      .line {
        // height: 46px;
        line-height: 46px;
        align-items: baseline;
        display: flex;
      }
      .line2 {
        // height: 46px;
        line-height: 46px;
        align-items: baseline;
        display: flex;
        flex-wrap: wrap;
      }
      .label {
        display: inline-block;
      }

      .text-right {
        text-align: left;
      }
      .budget {
        position: absolute;
        right: 25px;
        top: 25px;
        .des {
          font-size: 12px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.45);
        }
        .num {
          display: flex;
          align-items: baseline;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.65);
          .num1,
          .num3 {
            font-size: 10px;
          }
          .num2 {
            margin-left: 4px;
            margin-right: 8px;
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
      .monthwrap {
        width: 450px;
        margin-left: 10px;
        .ant-checkbox + span {
          width: 50px;
        }
        // padding-top: 20px;
      }
    }
  }
}

@primary-color: #333333;@layout-body-background: #f2f3f5;@menu-item-active-bg: #F2F3F5;@select-item-selected-bg: #F2F3F5;