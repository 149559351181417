.wrap {
  :global {
    .division-filter {
      height: 48px;
      line-height: 4px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .line {
      display: flex;

      .line-1-left,
      .line-1-right {
        flex: 1;
        width: 50%;
        .title {
          height: 48px;
          color: #262626;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          line-height: 48px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
    .downLoadTable {
      display: flex;
      justify-content: right;
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .breadcrumb {
      margin-bottom: 16px;
      margin-top: 5px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);

      .current {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
      }
      .root {
        cursor: pointer;
      }
    }
  }
}

@primary-color: #333333;@layout-body-background: #f2f3f5;@menu-item-active-bg: #F2F3F5;@select-item-selected-bg: #F2F3F5;