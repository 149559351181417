.wrap {
  // background-color: #fff;
  :global {
    .division-filter {
      padding: 0 24px;
      height: 64px;
      line-height: 64px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
    }
    .closeTool {
      width: 120px;
      position: absolute;
      top: 0px;
      left: 45%;
      z-index: 10;
    }
    .yearmonthbudget {
      padding-top: 14px;
      padding: 24px;
      position: relative;

      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
      // background-color: red;
      // margin-top: 16px;
      .bottomTool {
        margin-top: 16px;
      }
      .line {
        // height: 46px;
        line-height: 40px;
        align-items: baseline;
      }
      .label {
        width: 120px;
      }
      .budget {
        position: absolute;
        right: 25px;
        top: 25px;
        .des {
          font-size: 12px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.45);
        }
        .num {
          display: flex;
          align-items: baseline;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.65);
          .num1,
          .num3 {
            font-size: 10px;
          }
          .num2 {
            margin-left: 4px;
            margin-right: 8px;
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
      .monthwrap {
        width: 450px;
        margin-left: 10px;
        .ant-checkbox + span {
          width: 50px;
        }
        // padding-top: 20px;
      }
      .yearmonthbudget2 {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
      }
      .chartPan {
        width: 30%;
        display: inline-block;
        position: absolute;
        right: 0;
        top: 1px;
        bottom: 0;
      }

      .time {
        margin-left: 12px;
        color: rgba(0, 0, 0, 0.55);
      }
    }
  }
}

@primary-color: #333333;@layout-body-background: #f2f3f5;@menu-item-active-bg: #F2F3F5;@select-item-selected-bg: #F2F3F5;