.wrap {
  background-color: #fff;
  // margin: 16px;
  padding: 24px;
  height: calc(100vh - 48px - 24px);

  :global {
    .title {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
    }

    .type {
      margin-top: 8px;
    }

    .uploadwrap {
      .des {
        margin-top: 16px;
        height: 38px;
        line-height: 38px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }

      width: 440px;
      height: 170px;
      .btn {
        margin-top: 30px;
      }
    }
  }
}

@primary-color: #333333;@layout-body-background: #f2f3f5;@menu-item-active-bg: #F2F3F5;@select-item-selected-bg: #F2F3F5;